body,
html {
  background-color: #2bbfd3;
}

.headersvgclass {
  fill: white;
}

img {
  opacity: 1;
  transition: opacity 0.3s;
}

img[data-srcset] {
  opacity: 0;
}

[srcset] {
  opacity: 1;
  transition: opacity 0.3s;
}

[data-srcset] {
  opacity: 0;
}

.svgbutton {
  height: 40px;
  pointer-events: auto;
  cursor: pointer;
}

.svgbutton {
  transition: all 0.2s ease;
  pointer-events: auto;
  cursor: pointer;
}

.svgbutton:hover {
  transform: scale(1.1);
}

/*----- DETAILS CLASS FOR SLICK SLIDER ----- */
.details {
  transform: translateY(10px);
  pointer-events: auto;
  cursor: pointer;
  padding-right: 30px;
}
@media (max-width: 770px) {
  .details {
    transform: translateY(-10px);
    height: 20px;
    padding-right: 15px;
  }
}

/*----- LOGO ----- */
.logo {
  /* width: 150px; */
  z-index: 99;
  fill: var(--brandcolor);
  cursor: pointer;
  margin: auto 0;
  height: 60px;
  width: 180px;
}

.logo-pointer {
  cursor: pointer;
}

/* .logo,
.spin {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
} */

.logo-mobile {
  z-index: 99;
  fill: var(--brandcolor);
  width: 60px;
  height: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: auto 0;
}

/* .logo-mobile,
.spin {
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
} */

.logo-landscape {
  margin-top: 5px;
  z-index: 99;
  fill: var(--brandcolor);
  cursor: pointer;
  transition: all 0.2s ease;
  margin: auto 0;
  height: 60px;
  width: 180px;
}
.logo-not-landing {
  z-index: 99;
  fill: var(--brandcolor);
  cursor: pointer;
  transition: all 0.2s ease;
  margin: auto 0;
  height: 60px;
  width: 180px;
}
/* @-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */
/* svg.logo-landscape.svgchanger {
  width: 140px !important;
  top: 1rem !important;
} */
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .logo {
    width: 110px !important;
  }
  .logo-not-landing {
    width: 110px !important;
  }
  .logo-landscape {
    margin: auto 0;
  }
}
@media only screen and (min-device-width: 812px) and (max-device-width: 1024px) {
  .logo-not-landing {
    margin: auto 0;
    width: 130px !important;
  }
  .logo-landscape {
    margin: auto 0;
    width: 130px !important;
  }
}

/*----- INDEX EXPAND FOR HEADER ----- */
.index-expand {
  position: relative;
  width: 100%;
  height: 130px;
  z-index: 100;
  background-color: #1e8ea6;
}
.index-expand-tablet {
  height: 440px;
  background-color: #1f0529;
}
.index-expand-tablet.account {
  height: 150px;
}
@media screen and (max-width: 1024px) {
  .index-expand {
    height: 500px;
  }
  .index-expand.account {
    z-index: 1;
    height: 150px;
  }
  @media screen and (orientation: landscape) {
    .index-expand {
      height: 365px;
    }
    .index-expand.account {
      height: 150px;
    }
  }
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;
  /* background-color: #60c6ca; */
  padding: 0px;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide {
  visibility: hidden;
}
.slick-slide.slick-active {
  visibility: visible;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

@media (max-width: 770px) {
  .prev {
    display: none;
  }
}
